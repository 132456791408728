import React, { useState, useEffect } from "react";
import "./styles.scss";
import Database from "../../_utils/Database";

export default function CookiesButton() {
    const [accepted, setAccepted] = useState(false);

    useEffect(() => {
        setAccepted(Database.getCookieAccepted());
    }, []);

    const accept = () => {
        Database.setCookieAccepted(true);
        setAccepted(true);
    };

    const reject = () => {
        Database.removeCookieAccepted();
        setAccepted(false);
    };

    return !accepted ? (
        <button
            className="btn btn-primary m-3"
            onClick={accept}>
            Accept Cookies
        </button>
    ) : (
        <button
            className="btn btn-primary m-3"
            onClick={reject}>
            Reject Cookies
        </button>
    );
}
